
import React, { useEffect } from 'react';
import { api, CopyRightFooter } from '../../utils';
import { LoginForm } from '../loginForm/login';
import styles from './index.less';

if (!localStorage.browserCode) {
  const agent = navigator.userAgent.toLowerCase();
  const regStrChrome = /chrome\/[\d.]+/gi;
  if (agent.indexOf('chrome') > 0) {
    const chromeCode = agent.match(regStrChrome);
    localStorage.browserCode = chromeCode[0];
  }
}

/**
 * 调整首页ico
 */
const useAsLogo = () => {
  useEffect(() => {
    const link = document.querySelector<HTMLLinkElement>('link[rel*=\'icon\']');
    link.href = `${process.env.REACT_APP_OSS}customer-source/common/as.ico`;
  }, []);
};

const AnchorLogin = () => {
  useAsLogo();
  return (
    <div className={styles.container}>
      <div className={styles.login}>
        <img
          className={styles.background}
          src={`${api.oss}/images/anchorLoginBg.png`}
        />
        <div className={styles.content}>
          <img
            className={styles.logo}
            src={`${api.oss}/images/anchorLoginLogo.png`}
          />
          <LoginForm
            accountType={2}
            changePasswordPath="/egenie-ts-iac/findPassword"
            loginPath="/egenie-ts-iac/anchorLogin"
            sysType="pc_anchor"
            tenantModule="100024"
          />
        </div>
        <CopyRightFooter
          className={styles.footer}
          textClassName={styles.footer}
        />
      </div>
    </div>
  );
};
export default AnchorLogin;

